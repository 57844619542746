const inProduction = process.env.NODE_ENV === `production`

// Core-UI renders CallRail's JS to the body at build
const swapPhoneNumbers = () => {
  if (inProduction && `CallTrk` in window) {
    window.CallTrk.swap()
  }
}

exports.onInitialClientRender = swapPhoneNumbers

exports.onRouteUpdate = swapPhoneNumbers
