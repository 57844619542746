module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sundog Homes","short_name":"Sundog Homes","start_url":"/","background_color":"#171923","theme_color":"#E53E3E","display":"minimal-ui","icon":"static/favicons/favicon.svg","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"9d01cca84e7a307d71f8c8d82f1ce857"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MN5KW4V","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{}},"enableWebVitalsTracking":true,"routeChangeEventName":"route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/@avldev/gatsby-theme-core-ui/gatsby-browser.js'),
      options: {"plugins":[],"includeSnippetsInDevelopment":false,"callrail":{"key":"981495895","token":"e579ecfe16416f1daa16"},"googleReviews":{"createReviewNodes":false,"settings":{"key":"ChIJoS2U-j7zWYgROXyjlRkQR9s","minimumRating":4,"nodeType":"GoogleReview","numberOfReviews":48,"orderBy":"date","withTextOnly":true}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
